export default [
    {name:"PageIndex",path:"/",component:()=>import("@/views/PageIndex.vue")},
   // 模型管理界面
    {name:"DeviceIndex",path:"/device",component:()=>import("@/views/device/DeviceIndex.vue")},
    {name:"PlanIndex",path:"/plan",component:()=>import("@/views/plan/PlanIndex.vue")},
    {name:"PlanInfo",path:"/plan/info",component:()=>import("@/views/plan/PlanInfo.vue")},
    /* 用户管理 */
    {name:"UserIndex",path:"/user",component:()=>import("@/views/user/UserIndex.vue")},
    {name:"PatrolIndex",path:"/patrol",component:()=>import("@/views/patrol/index.vue")},
    {name:"ServiceIndex",path:"/service",component:()=>import("@/views/service/index.vue")}
];
