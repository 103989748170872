<template>
    <div class="z-card" :style="{width:width}" :class="shadow">
      <div v-if="showHeader" class="header">
        <div class="title">
          <slot name="title">
            {{title}}
          </slot>
        </div>
        <div class="extra" @click="extraClick">
          <slot name="extra">
            <span class="btn">{{extra}}</span>
          </slot>
          <span class="link" v-if="isLink">
            <el-icon>
              <ArrowRight />
            </el-icon>
          </span>
        </div>
      </div>
      <div class="body">
        <slot></slot>
      </div>
      <div v-if="showFooter" class="footer">
        <slot name="footer">
         <div class="footer-content">
             <div class="left">
               <slot name="footer-left" />
             </div>

             <div class="center">
               <slot name="footer-center" />
             </div>

             <div class="right">
               <slot name="footer-right" />
             </div>
         </div>
        </slot>
      </div>
    </div>
</template>
<script>
import {ArrowRight} from "@element-plus/icons-vue";
export default {
  components:{ArrowRight},
  name: "ZCard",
  emits:["extra-click"],
  props:{
    shadow:{
      type:String,
      default(){
        return "always"
      }
    },
    width:{
      type:String,
      default(){
        return "auto"
      }
    },
    title:{
      default(){
        return "";
      }
    },
    extra:{
      default(){
        return "";
      }
    },
    showHeader:{
      default(){
        return true;
      }
    },
    isLink:{
      type:Boolean,
      default(){
        return false
      }
    },
    showFooter :{
      type:Boolean,
      default(){
        return false;
      }
    },
  },
  methods:{
    extraClick(){
      this.$emit("extra-click");
    }
  }
}
</script>

<style scoped lang="scss">
.z-card{
  --body-padding:10px;
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  background-color: #fff;
  &.always{
    box-shadow: var(--el-box-shadow-light);
  }
  &.hover:hover{
    box-shadow: var(--el-box-shadow-light);
  }
  >.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 14px;
    border-bottom: 1px solid #EBEEF5;
    .title{
      flex:1;
      font-size: 16px;
      color: #1a1a1a;
    }
    .extra{
      user-select: none;
      cursor: pointer;
      .btn{
        font-size:14px;
        color: #909399;
      }
      .link{
        vertical-align: middle;
        color: #909399;
      }
    }
  }
  >.body{
    padding: var(--body-padding);
    font-size:14px;
  }
  >.footer{
    .footer-content{
      border-top: 1px solid #EBEEF5;
      display: flex;
      justify-content: space-between;
      padding:8px 20px;
      .center{
        text-align: center;
        flex: 1;
      }
    }
  }

}
</style>