import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import element from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import zhCh from 'element-plus/dist/locale/zh-cn'
import custom from '@/modules/npm'
import '@/assets/styles.scss'
createApp(App)
    .use(store)
    .use(router)
    .use(element,{locale:zhCh})
    .use(custom,{
        baseUrl:"/index.php/",
        pictureList:"/index.php/picture/list",
        uploadPicture:"/index.php/picture/upload",
    })
    .mount('#app')
/*
npm i git+https://125184307@qq.com:zhaoxudong1994@gitee.com/FreedomGeek/npm.git#master
 */