<template>
<div class="z-form-layout">
  <div class="header" v-if="title">
      {{title}}
  </div>
  <div ref="boydRef" class="form-content">
    <el-scrollbar :max-height="size.height+'px'">
      <slot name="form">
          <el-form ref="form" :model="model" :rules="rules">
            <slot></slot>
          </el-form>
      </slot>
    </el-scrollbar>
  </div>
  <div class="footer-bar">
    <slot name="footer-bar">
      <div class="left">
        <slot name="footer-left"></slot>
      </div>
      <div class="center">
        <slot name="footer-center">
          <el-button>返回</el-button>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </slot>
      </div>
      <div class="right">
        <slot name="footer-right"></slot>
      </div>
    </slot>
  </div>
</div>
</template>
<script>
export default {
  name: "ZFormLayout",
  props:{
    width:{
      default(){
        return "";
      }
    },
    title:{
      default(){
        return ""
      }
    },
    model:{
      default(){
        return {}
      }
    },
    rules:{
      default(){
        return []
      }
    }
  },
  data(){
    return {
      size:{
        width:0,
        height:0
      }
    }
  },
  mounted() {
    this.updateSize();
    window.onresize = this.updateSize;
  },
  methods:{
    onSubmit(){
      if(this.$refs.form){
        this.$refs.form.validate(valid=>{
          if(valid){
            this.$emit("submit")
          }
        })
      }else{
        this.$emit("submit")
      }
    },
    updateSize(){
      const {offsetWidth,offsetHeight} = this.$refs.boydRef;
      this.size.width = offsetWidth;
      this.size.height = offsetHeight;
    }
  },
  computed:{
    formRef:()=>{
      return this.$refs.form;
    }
  }
}
</script>
<style scoped lang="scss">
.z-form-layout{
  display: flex;
  height:calc(100% - 20px);
  flex-direction: column;
  margin:20px 20px 0 20px;
  .header{
    border-left: 3px solid #08f;
    padding: 10px 7px;
  }
  .form-content{
    padding:10px 15px;
    flex: 1;
    overflow: hidden;
  }
  .footer-bar{
    border-top:1px solid #e4e7ed;
    display: flex;
    justify-content: space-between;
    padding:12px 17px;
    >.center{
      text-align: center;
      flex:1;
    }
  }
}
</style>