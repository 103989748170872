import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import AppLayout from '../views/AppLayout.vue'
import RouterList  from'./routers'
const routes = [
  {
    path: '/',
    name: 'appLayout',
    component: AppLayout,
    children:RouterList
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  }
]

const router = createRouter({
  history:  createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
