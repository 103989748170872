<template>
  <el-space wrap class="tag-manager">
    <template v-for="(item,key) in modelValue" :key="key">
      <el-tag v-if="!props.needCall  || item.delete_time === 0" class="tag-item" @click="tagClick(item)" size="large" >
        <slot :data="item">{{item}}</slot>
        <el-icon @click="removeItem(key)"><Close /></el-icon>
      </el-tag>

    </template>

    <el-input @blur="inputBlur" @keydown.enter="inputBlur" ref="InputDom" v-if="status.isAdd" v-model="addValue"></el-input>
    <el-button @click="addItem" v-else type="primary">新增</el-button>
  </el-space>
</template>
<script setup>
import {defineProps, computed, defineEmits, ref, nextTick} from 'vue'
import {Close} from '@element-plus/icons-vue'
const InputDom = ref(null);
const emit = defineEmits(["update:modelValue",'addItem']);
const addValue = ref("");
const status = ref({
  isAdd:false,
})
const props = defineProps({
  modelValue:{
    default(){
      return []
    }
  },
  unique:{
    type:Boolean,
    default(){
      return false
    }
  },
  needCall:{
    type:Boolean,
    default(){
      return false;
    }
  },
})
const modelValue = computed({
  get(){
    return props.modelValue;
  },
  set(value){
    emit("update:modelValue",value);
  }
})
const tagClick = (item)=>{
  emit("click",item)
}
const addItem = ()=>{
  status.value.isAdd = true;
  nextTick(() => {
    InputDom.value.input.focus()
  })
}
const addCallback = (value)=>{
  console.log(value);
  modelValue.value.push(value);
  emit("update:modelValue",modelValue.value);
}
const inputBlur = ()=>{
  if(addValue.value){
    if(props.unique === false || modelValue.value.indexOf(addValue.value) === -1){
      if(props.needCall){
        emit("addItem",{v:addValue.value,c:addCallback})
      }else{
        addCallback(addValue.value);
      }
    }
  }
  status.value.isAdd = false;
  addValue.value = "";
}
const removeItem =(index)=>{
  if(props.needCall){
    emit("deleteItem",index)
  }else{
    modelValue.value.splice(index,1);
    emit("update:modelValue",modelValue.value);
  }
}


</script>
<style lang="scss">
.tag-manager{
  .tag-item{
    .el-tag__content{
      display: flex;
      align-items: center;
      user-select: none;
      justify-content: space-between;
      .el-icon{
        margin-left: 7px;
        &:hover{
          color: red;
        }
      }
    }
  }
}
</style>