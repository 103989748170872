<template>
  <div class="z-data-layout">
    <div class="header" v-if="showHeader">
      <div class="left">
        <el-form inline @submit.prevent @keydown.enter="changePage(1)" >
          <slot name="header-left"></slot>
        </el-form>
      </div>
      <div class="right"><slot  name="header-right"></slot></div>
     </div>
    <div ref="dataBodyRef" class="body" :class="{scroll:scroll}">
      <component :is="scroll?'el-scrollbar':'div'" :max-height="size.height+'px'">
        <slot :size="size"></slot>
      </component>
    </div>
    <div class="footer" v-if="showFooter">
      <el-pagination :page-size="20" background small :total="total" @current-change="changePage"></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "ZDataLayout",
  emits:['onQuery'],
  props:{
    showHeader:{
      type:Boolean,
      default(){
        return true
      }
    },
    showFooter:{
      type:Boolean,
      default(){
        return true
      }
    },
    scroll:{
      type:Boolean,
      default(){
        return false;
      }
    },
    total:{
      type:Number,
      default(){
        return 0;
      }
    }
  },
  data(){
    return {
      size:{
        width:0,
        height:0,
      },
    }
  },
  mounted() {
    this.changeSize();
    window.onresize =  this.changeSize;
  },
  methods:{
    changePage(e){
      this.$emit("onQuery",e);
    },
    changeSize(){
      const {offsetWidth,offsetHeight} = this.$refs.dataBodyRef;
      this.size.width  = offsetWidth;
      this.size.height = offsetHeight;
    }
  },
}
</script>
<style lang="scss">
.z-data-layout{
  height: 100%;
  display: flex;
  flex-direction: column;
  >.header{
    height: auto;
    padding: 10px 15px 0 15px;
    border-bottom:1px solid #EBEEF5;
    display: flex;
    justify-content: space-between;
    .el-form{
      .el-form-item{
        margin-bottom: 10px!important;
      }
    }
  }
  >.body{
    flex:1;
    &.scroll{
      overflow: hidden;
    }
  }
  >.footer{
    border-top:1px solid #EBEEF5;
    justify-content: right;
    padding: 10px 12px;
  }
}
</style>