<template>
  <el-dialog v-model="isShow" title="图片选择器">
    <div class="picker-container">
      <div class="picker-list"></div>
      <div class="picker-page"></div>
    </div>
  </el-dialog>
</template>
<script>
/*
*  看传入的参数，如果是个字符串类型，则
*
* */
export default {
  name: "ZPicturePicker",
  props:{
    modelValue:{
      type:Array||String,
      default(){
        return [];
      }
    }
  },
  data(){
    return{
      isShow:true,
    }
  }
}
</script>
<style scoped>


</style>