<template>
  <el-switch :active-text="activeText"
             :active-value="activeValue"
             :inactive-text="inactiveText"
             :inactive-value="inactiveValue"
             :model-value="modelValue"
             @change="changeValue"
             :loading="loading">
  </el-switch>
</template>

<script>
export default {
  name: "ZSwitchLoading",
  props:{
    modelValue:{
      default(){
        return ""
      }
    },
    activeValue:{
      required:true,
      default(){
        return ""
      }
    },
    inactiveValue:{
      required:true,
      default(){
        return ""
      }
    },
    activeText:{
      default(){
        return "开启"
      }
    },
    inactiveText:{
      default(){
        return "关闭"
      }
    },
  },
  data(){
    return {
      value:"",
      loading:false,
    }
  },
  methods:{
    changeValue(e){
      this.loading = true;
      let callback = (state)=>{
        if(typeof state === 'boolean'){
          if(state){
            this.updateValue(e);
          }else{
            this.loading = false;
          }
        }else{
          this.updateValue(state);
        }
      }
      this.$emit("update",{callback:callback,value:e});
    },
    updateValue(value){
      this.$emit("update:modelValue",value);
      this.loading = false;
    }
  },
}
</script>

<style scoped>

</style>