import _ from 'lodash'
import axios from 'axios'
import qs from 'qs'
import {ElLoading} from "element-plus";
import router from "@/router";
import {ElNotification} from 'element-plus'
import ZCard from "./components/ZCard.vue";
import ZDataLayout from "./components/ZDataLayout.vue";
import ZFormLayout from "./components/ZFormLayout.vue";
import ZSwitchLoading from "./components/ZSwitchLoading.vue";
import ZPicturePicker from "./components/ZPicturePicker.vue";
import ZUploadPicture from "./components/ZUploadPicture.vue";
import ZTagManager from "./components/ZTagManager.vue";
const components = {
    ZCard,ZDataLayout,ZFormLayout,ZSwitchLoading,ZPicturePicker,ZUploadPicture,ZTagManager
}
export default {
    install(Vue,options){
        window.custom_conifg = options;
        Object.keys(components).forEach(key=>{
            const component = components[key];
            Vue.component(key,component);
        })
    }
}
// 对时间进行格式化
export const formatDate = (date,format = "yyyy-mm-dd")=>{
    if(typeof date === 'string'){
        date = new Date(date);
    }else if(date === null || date === undefined){
        date = new Date();
    }
    let year =  date.getFullYear();
    let month = date.getMonth()+1
    if(month < 10){
        month = `0${month}`
    }
    let day   = date.getDate();
    if(day < 10){
        day = `0${day}`
    }
    return format.replace("yyyy",year).replace("mm",month).replace("dd",day);
}
// 加载JS文件
export const importJs = (key,url)=>{
    return new Promise((resolve)=>{
        let js = document.getElementById(key);
        if(js != null){
            js.remove();
        }
        js = document.createElement("script");
        js.src = url;
        js.onload = ()=>{
            resolve(window[key]);
        }
        document.head.appendChild(js);
    })
}
// 合并两个对象
export const cloneDeep = (var1,var2)=>{
    Object.keys(var1).forEach(key=>{
        if(var2[key]){
            var1[key] = _.cloneDeep(var2[key]);
        }
    })
    return var1;
}

const ajax = axios.create({
    baseURL:"/index.php/",
    timeout:10000
});
// 请求拦截器
ajax.interceptors.request.use(config=>{
    if(config.method === 'post'){
        config.data = qs.stringify(config.data);
    }
    return config;
},err => {
    return new Promise.reject(`请求失败，失败原因 :${err.message}`);
});
// 响应拦截器
ajax.interceptors.response.use(config=>{
    if(config.status === 200){
        const response = config.data;
        if(response.code === 201){
           const nowPage =  router.currentRoute.value.path;

            router.push({
                name:"login",
                query:{
                    callback:escape(nowPage)
                }
            })
        }else{
            return Promise.resolve(response);
        }
        return Promise.reject(response.message);
    }else{
        return Promise.reject(`服务器繁忙:${config.statusText}`);
    }
},err=>{
    return Promise.reject(`服务器繁忙:${err.message}`);
});
export const get = (url,params = {})=> ajax.get(url,{params:params});
export const post =(url,data={}) => ajax.post(url,data);
export const api = (fn,message="正在加载中...") => {
    return (...args) => {
        const service = ElLoading.service({
            text: message
        });
        return new Promise((resolve, reject) => {
            fn(...args).then(resolve).catch(reject).finally(() => {
                service.close();
            })
        })
    }
}
export const downloadfile = (url,params)=>{
    return ajax.get(url,params,{
        headers:{
            responseType:'blob'
        }
    });
}

export const WithNotice = (fn)=>{
    return (...args)=>{
        return new Promise(((resolve) => {
            return fn(...args).then(res=>{
                console.log(res);
                if(res.code === 1){
                    if(res.msg){
                        ElNotification.success({title:"提示",message:res.msg});
                    }
                    resolve(res.data);
                }else{
                    ElNotification.error({title:"错误提示",message:res.msg});
                }
            }).catch(err=>{
                ElNotification.error({title:"错误提示",message:err});
            })
        }))
    }
}