<template>
  <el-upload
      v-loading="loading"
      element-loading-text="上传中..."
      class="upload-picture"
      :show-file-list="false"
      :action="config.uploadPicture"
      with-credentials
      :on-success="handleSuccess"
      :before-upload="beforeUpload"
  >
    <img class="upload-picture-show" v-if="modelValue && modelValue.file_url" :src="modelValue.file_url" alt=""  />
    <el-icon v-else class="upload-btn">
      <Plus />
    </el-icon>
  </el-upload>
</template>

<script>
import {Plus} from "@element-plus/icons-vue";
export default {
  components:{Plus},
  name: "ZUploadPicture",
  props:{
    modelValue:{
      default(){
        return {}
      }
    }
  },
  data(){
    return {
      loading:false,
    }
  },
  methods:{
    beforeUpload(rawFile){
      if (rawFile.size / 1024 / 1024 > 10) {
        this.$message.error('上传的图片大小不能超过10M!')
        return false
      }
      this.loading = true;
      return true;
    },
    handleSuccess(response){
      if(response.code === 201){
        this.$router.push({name:"login"});
      }else if(response.code === 1){
        this.$emit("update:modelValue",response.data);
      }else{
        this.$message.error(response.msg);
      }
      this.loading = false;
    },
  },
  computed:{
    config(){
      return window.custom_conifg;
    }
  }
}
</script>

<style lang="scss">
:root{
  --upload-block:140px;
}
.upload-btn,.upload-picture{
  width:var(--upload-block);
  height:var(--upload-block);
}
.upload-picture-show{
  max-width:var(--upload-block) ;
  max-height:var(--upload-block) ;
}
.upload-picture{
  padding:7px;
  border: 1px dashed #e4e7ed;
  border-radius: 4px;
  .upload-btn{
    font-size: 40px;
    color:#e4e7ed;
    text-align: center;
    line-height: var(--upload-block);
  }
}
</style>