<template>
<el-container>
  <el-aside width="100px">
    <div class="z-aside">
      <div class="logo">GBPT</div>
      <el-menu router>
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/device">设备</el-menu-item>
        <el-menu-item index="/user">人员</el-menu-item>
        <el-sub-menu>
          <template #title>巡检</template>
          <el-menu-item index="/patrol">记录</el-menu-item>
          <el-menu-item index="/plan">计划</el-menu-item>
        </el-sub-menu>
        <el-menu-item index="/service">维修</el-menu-item>
      </el-menu>
    </div>
  </el-aside>
  <el-container>
    <el-header></el-header>
    <el-main>
      <router-view />
    </el-main>
  </el-container>
</el-container>
</template>

<script>
export default {
  name: 'AppLayout',
}
</script>
<style lang="scss">
.z-aside{
  .logo{
    height: 45px;
    text-align: center;
    line-height: 45px;
  }
  height:100%;
  display: flex;
  flex-direction: column;
  .el-menu{
    flex:1;
  }
}
</style>